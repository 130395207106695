/* 1. Components */
@import 'components/example-viewer';
@import 'components/input';

/* 2. Overrides */
@import 'overrides/angular-material';
@import 'overrides/highlightjs';
@import 'overrides/perfect-scrollbar';
@import 'overrides/quill';

.loaderMain{
    position: absolute;
    width: 100%;
    height: -webkit-fill-available;
    height: 100% ;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: white;
    z-index: 9999;
    top: 0;
    left: 0;
}

.loaderText{
    font-size: 32px;
    margin-top: 30px;
    color: #0b3b4a;
}
.LoaderImage{
    width:300px !important;display: inline-block;
}
body.light .mat-flat-button.mat-primary, body.light .mat-raised-button.mat-primary, body.light .mat-fab.mat-primary, body.light .mat-mini-fab.mat-primary, body .light .mat-flat-button.mat-primary, body .light .mat-raised-button.mat-primary, body .light .mat-fab.mat-primary, body .light .mat-mini-fab.mat-primary {
    background-color:#66cc00 !important;
}
.color-orange-text {
    color:#66cc00 !important;
}
body.light .mat-step-header .mat-step-icon-selected, body.light .mat-step-header .mat-step-icon-state-done, body.light .mat-step-header .mat-step-icon-state-edit, body .light .mat-step-header .mat-step-icon-selected, body .light .mat-step-header .mat-step-icon-state-done, body .light .mat-step-header .mat-step-icon-state-edit {
    background-color:#66cc00 !important;
    color: var(--fuse-on-primary);
}

.clickable{
    color: #66cc00 !important;
    cursor: pointer !important;
    font-weight: 600 !important;
}
.clickable:hover{
    color: grey !important;
    cursor: pointer !important;
    font-weight: 600 !important;
}

$small: 320px;
$medium: 425px;

.mainDiv {
  @media (min-width: $small) and (max-width: 424px) {
    text-align: center !important;
    display: grid !important;
  }
  @media (min-width: $medium)  {
    text-align: center !important;
    display: flex !important;
    justify-content: space-between !important;
  }
}

.items {
    @media (min-width: $small) and (max-width: 424px)  {
        flex: 1 1 0px;justify-content: space-between;
    }
    @media (min-width: $medium) {
        flex: 1 1 0px;justify-content: space-between;
        max-width: 49%;
    }
  }

.error-message{
    color: var(--fuse-warn);
    font-size: 12px;
    display: block;
    font-weight: 500;
    position: relative;
    top: -11px;
}
.error-boarder{
    border-color: var(--fuse-warn) !important;
}

#welcome > p:nth-child(1) {
    font-size: 42px;
    line-height: 45px;
}