/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
.close-modal-button {
    background-color: #e2e8f0 !important;
    width: 200px;
    border-radius: 20px;
    padding: 10px;
  
    &:hover {
      background-color: #c9c9c9 !important;
    }
  }
.transform-text{
    text-transform: capitalize !important;
}

.clickable {
    color: #66cc00 !important;
    cursor: pointer !important;
    font-weight: 600 !important;
    text-decoration: underline !important;
}
.accordion-heading {
  font-size: 16px;
  font-weight: 500;
}

.schedule-header { 
  height: 48px !important;
  border-bottom: 1px solid #c9c9c9;
}

.schedule-tab-button {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

@media only screen and (max-width: 600px) {
  .schedule-tab-button {
    display: flex;
    justify-content: center !important;
    padding-top: 20px;
  }
}



app-instruction-guide {
  .instruction-dashboard {
    #welcome {
      ol {
        &:nth-child(n) {
          list-style: auto;
          margin-left: 15px;
        }
      }
    } 
  } 
} 
